<template>
  <div class="login">
    <div class="title">拼个班视频版后台</div>
    <div class="body" v-loading="loading">
      <el-card :body-style="{ padding: '0px' }">
        <div style="padding: 30px">
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="用户名" prop="name">
              <el-input
                v-model="ruleForm.name"
                auto-complete="off"
                @keyup.enter.native="submitForm('ruleForm')"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                auto-complete="off"
                @keyup.enter.native="submitForm('ruleForm')"
              ></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 50px">
              <el-button @click="resetForm('ruleForm')">重置</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { login, getUploadToken } from "@/api/base";
import Cookies from "js-cookie";
import MENU from "@/util/menu.js";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      ruleForm: {
        name: "",
        password: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login({
            uname: this.ruleForm.name,
            pwd: this.ruleForm.password,
          })
            .then((res) => {
              if (res.code == 200) {
                Cookies.set("token", res.data.token);
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res.data.admin_info)
                );
                localStorage.setItem(
                  "auth_menu_json",
                  JSON.stringify(res.data.auth_menu_json)
                );
                if (res.data.auth_menu_json.length > 0) {
                  MENU.forEach((item) => {
                    if (item.name == res.data.auth_menu_json[0].menu_name) {
                      item.child.forEach((it) => {
                        if (
                          it.url ==
                          res.data.auth_menu_json[0].children[0].menu_route
                        ) {
                          this.$router.push(
                            res.data.auth_menu_json[0].children[0].menu_route
                          );
                          this.$router.push({
                            path: it.url,
                            query: {
                              group: item.group,
                              item: it["group-item"],
                            },
                          });
                        }
                      });
                    }
                  });
                } else {
                  this.$message.error("暂无权限");
                }

                this.getUploadToken();
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取七牛云上传视频
    getUploadToken() {
      getUploadToken()
        .then((res) => {
          localStorage.setItem("qn-token", res.data.upload_token);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  background-color: #fff;
  width: 1200px;
  margin: 0 auto;
  min-height: 700px;
  .title {
    height: 100px;
    background: #409eff;
    color: #fff;
    text-align: center;
    font-size: 30px;
    line-height: 100px;
    font-family: "Microsoft Yahei", "SimSun";
  }
  .body {
    width: 400px;
    margin: 180px auto;
  }
  .el-form {
    margin-left: -50px;
  }
  .el-button {
    margin-top: 20px;
  }
}
</style>
